<template>
  <section class="about">
    <h1>Waarom deze site?</h1>
    <p>
      Onze zoon heeft al heel wat meegemaakt. Deze site heb ik gemaakt, enerzijds om zelf niet
      altijd de uitleg te moeten doen. Anderzijds is het de perfecte manier om hem later er zoveel
      mogelijk over te kunnen vertellen.
    </p>
  </section>
</template>

<script>
  export default {
    setup() {
      return {}
    },
  }
</script>

<style lang="scss" scoped>
  .about {
    display: grid;
    margin: 75px auto 0;
    max-width: 1024px;
    padding: 1rem;
  }
</style>
